<template>
  <div class="about-container">
    <div class="part">
      <div class="title">Privacy Policy</div>
      <div class="content">
        <div class="content_list">
          We are concerned about your privacy and trust. We treat your data with care and never misuse them. This page
          is used to inform visitors regarding our policies with the collection, use, and disclosure of Personal
          Information if anyone decided to use our Service. If you choose to use our Service, then you agree to the
          collection and use of information in relation to this policy. The Personal Information that we collect is used
          for providing and improving the Service. We will not use or share your information with anyone except as
          described in this Privacy Policy.
        </div>

        <div class="content_title">Data Collection and Use</div>
        <div class="content_list">
          For better user experience and basic web infrastructure, we use some world famous reliable third party
          services like Google Analytics, AWS, Paypal and so on. Your data may be transferred, used and stored in these
          services, but we ensure all your data will only be used for photo processing related purposes.
        </div>

        <div class="content_title">Log Data</div>
        <div class="content_list">
          We want to inform you that whenever you use our service, in case of an error in the app we collect data and
          information (through third party products) called Log Data. This Log Data may include information such as your
          device Internet Protocol (“IP”) address, device name, operating system version, the configuration of the app
          when utilizing our Service, the time and date of your use of the Service, and other statistics.
        </div>

        <div class="content_title">Data Deletion Policy</div>
        <div class="content_list">
          In general, any user data stored will be deleted automatically in a short period of time after the usage of
          the service ends.
        </div>

        <div class="content_title">Children’s Privacy</div>
        <div class="content_list">
          These Services do not address anyone under the age of 16. We do not knowingly collect personally identifiable
          information from children under 16. In the case we discover that a child under 16 has provided us with
          personal information, we immediately delete this from our servers. If you are a parent or guardian and you are
          aware that your child has provided us with personal information, please contact us so that we will be able to
          do the necessary actions. Changes to This Privacy Policy We may update our Privacy Policy from time to time.
          Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by
          posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on
          this page.
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
// import { reportWebVitals " from "@/utils/ga.js";
import Footer from "@/components/Footer.vue";

export default {
  name: "about",

  data() {
    return {};
  },
  components: {
    Footer,
  },

  mounted() {
    const locale = this.$i18n.locale;
    const messages = this.$i18n.messages;
    const desc = messages[locale].message.index.desc;
    this.descList = desc.split("/");
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.about-container {
  width: 100%;
  padding-top: 50px;
  .part {
    min-height: calc(100vh - 234px);
    width: 1200px;
  margin: 0 auto;

    padding-bottom: 100px;
  }
  .title {
       font-size: 48px;
        text-align: center;
        color: rgb(48, 43, 78);
        margin-bottom: 28px;
  }
  .content {
    padding: 0 30px;
    // padding-left: 20px;
    .content_title {
       font-size: 22px;
            margin-bottom: 11px;
            margin-top: 26px;
            font-weight: 600;
    }
    .content_list {
    font-size: 15px;
            color: rgba(0,0,0,.65);
            line-height: 24px;
            margin-bottom: 15px;
            overflow-wrap: break-word;
            text-align: justify;
    }
  }
}
</style>
